import React from 'react';

import { FotoContainer, FotoResponsiveIframe } from './styles';

function PictureIframeResponsive({ urlFoto }) {
  return (
    <FotoContainer>
      <FotoResponsiveIframe
        src={urlFoto}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
      />
    </FotoContainer>
  );
}

export default PictureIframeResponsive;
