import React from 'react';
import PageDefault from '../../components/PageDefault';
import './index.css';

function Sobre() {
  return (
    <PageDefault>
      <h3>Bem vindo ao SAMMYFLIX !!</h3>
      <p className="texto">
        O SAMMYFLIX foi desenvolvido para fins de aprendizagem em que eu Samara Paula uma mera Desenvolvedora de Software estou aprendendo uma
        nova tecnologia, o REACT JS, e fiz este site para ampliar meus conhecimentos. Desenvolvi este site durante a Imersão React
        da Alura, foi uma longa semana de aprendizado, sou grata a todos os ministrantes (Mario Souto, Marco Bruno e Juliana Negreiros).
      </p>
      <p className="texto">
        No SAMMYFLIX você encontra algumas sugestões de vídeos relacionados a programação e banco de dados, além  de filmes e músicas.
        Fique a vontade para adicionar aqui vídeos que você também goste. =)
      </p>
      <br />
      <p className="texto">Este site ficará disponível por alguns dias.</p>
    </PageDefault>
  );
}

export default Sobre;
