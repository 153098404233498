import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo_sammyflix.png';
import Sobre from '../../assets/img/about.png';

import './Menu.css';
import Button from '../Button';

function Menu() {
  return (
    <nav className="Menu">
      <Link to="/">
        <img className="Logo" src={Logo} alt="SammyFlix" />
      </Link>

      <div>  
        <Button as={Link} className="ButtonLink" to="/cadastro/video">
            Novo vídeo
        </Button>
        
        <Link to="/Sobre">
            <img className="Sobre" src={Sobre} alt="Sobre" />
        </Link>
      </div>
    </nav>
  );
}

export default Menu;
