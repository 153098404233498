import React from 'react';
import VideoIframeResponsive from './components/VideoIframeResponsive';
import FotoIframeResponsive from './components/FotoIFrameResponsive';
import { BannerMainContainer, ContentAreaContainer, WatchButton } from './styles';
import Capa from '../../assets/img/sammy_capa.jpg';
import urlPhoto  from '../../assets/img/sammy_linguagens.png';

function getYouTubeId(youtubeURL) {
  return youtubeURL
    .replace(
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
      '$7',
    );
}

export default function BannerMain({
  videoTitle,
  videoDescription,
  url,
}) {
  const youTubeID = getYouTubeId(url);
  //const bgUrl = `https://img.youtube.com/vi/${youTubeID}/maxresdefault.jpg`;
  //backgroundImage={bgUrl}
  return (
    <BannerMainContainer backgroundImage={Capa} >
      
      <ContentAreaContainer>
        <ContentAreaContainer.Sammy>
          <FotoIframeResponsive
              urlFoto={urlPhoto}
            />
        </ContentAreaContainer.Sammy>
        <ContentAreaContainer.Item>
          <ContentAreaContainer.Title>
            {videoTitle}
          </ContentAreaContainer.Title>

          <ContentAreaContainer.Description>
            {videoDescription}
          </ContentAreaContainer.Description>
        </ContentAreaContainer.Item>

        <ContentAreaContainer.Item>
          <VideoIframeResponsive
            youtubeID={youTubeID}
          />
          <WatchButton>
            Assistir
          </WatchButton>
        </ContentAreaContainer.Item>
      </ContentAreaContainer>
    </BannerMainContainer>
  );
}
